import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';

function WhyChooseUs() {
    return (
        <section className="whychooseus">
            <div className="services p-2">
                <div className="container my-3 text-center p-5">
                    <div className="d-flex justify-content-center">
                        <div className="sub-title">
                            <h2 className="text-white fw-bold">WHY CHOOSE US</h2>
                        </div>
                    </div>
                    <p className="mt-4 text-white">A brand name you can always trust and rely on. Stay ahead of the competition!</p>

                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <div className="my-4 ms-auto me-0">
                                <div className="card whychooseus-card">
                                    <div className="icon-container">
                                        <FontAwesomeIcon className="fs-1 fa-thin" icon={faMoneyCheckDollar} />
                                    </div>
                                    <div className="card-body p-lg-4 p-5">
                                        <h5 className="fw-bold text-white">Affordable Price Guarantee</h5>
                                        <p className="card-text">
                                        Ensuring budget-friendly solutions without compromising quality – 
                                        making cutting-edge services accessible to all.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                <div className="card whychooseus-card">
                                    <div className="icon-container">
                                        <FontAwesomeIcon className="fs-1 fa-thin" icon={faMoneyCheckDollar} />
                                    </div>
                                    <div className="card-body p-lg-4 p-5">
                                        <h5 className="fw-bold text-white">Highly Qualified Service</h5>
                                        <p className="card-text">Experience excellence with our highly qualified service, 
                                        where industry expertise meets your unique needs for unparalleled solutions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="my-4">
                                <div className="card whychooseus-card">
                                    <div className="icon-container">
                                        <FontAwesomeIcon className="fs-1 fa-thin" icon={faMoneyCheckDollar} />
                                    </div>
                                    <div className="card-body p-lg-4 p-5">
                                        <h5 className="fw-bold text-white">Long Term Support</h5>
                                        <p className="card-text">Our commitment doesn't end with the delivery of the software. 
                                        We offer continuous support and maintenance services, ensuring that your systems run smoothly. 
                                        From updates to troubleshooting, we're here for you every step of the way.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                <div className="card whychooseus-card">
                                    <div className="icon-container">
                                        <FontAwesomeIcon className="fs-1 fa-thin" icon={faMoneyCheckDollar} />
                                    </div>
                                    <div className="card-body p-lg-4 p-5">
                                        <h5 className="fw-bold text-white">Scalable Architecture Design</h5>
                                        <p className="card-text">Built for growth, our software architectures are scalable to adapt to your evolving business needs. 
                                        Whether you're a startup or an enterprise, our solutions grow with you, providing a solid foundation for your future success.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4">
                            <div className="my-4">
                                <div className="card whychooseus-card">
                                    <div className="icon-container">
                                        <FontAwesomeIcon className="fs-1 fa-thin" icon={faMoneyCheckDollar} />
                                    </div>
                                    <div className="card-body p-lg-4 p-5">
                                        <h5 className="fw-bold text-white">Advanced Technology Stack</h5>
                                        <p className="card-text">
                                        From cloud-based solutions to the latest frameworks, 
                                        stay ahead in the digital landscape with our state-of-the-art technology stack. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                <div className="card whychooseus-card">
                                    <div className="icon-container">
                                        <FontAwesomeIcon className="fs-1 fa-thin" icon={faMoneyCheckDollar} />
                                    </div>
                                    <div className="card-body p-lg-4 p-5">
                                        <h5 className="fw-bold text-white">Super Fast Delivery</h5>
                                        <p className="card-text">
                                        Choose us for a commitment to quality, innovation, and personalized service
                                        with super fast delivery</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyChooseUs;