import React from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Home() {
    return (
        <section>
            <div className="hero position-relative">
                <div className="container p-5">
                    <div className="d-flex justify-content-center align-items-center text-center flex-column text-white">
                        <div className="">
                            <h1 className="fw-bold pt-5 px-2">
                                EMPOWERING
                            </h1>
                        </div>
                        <div>
                            <h3 className="px-2 fw-bold" style={{ fontFamily: "Courier New" }}>
                                YOUR DIGITAL PRESENCE!
                            </h3>
                        </div>
                        <div className="mt-3">
                            <p className="p-2" style={{ backgroundColor: "#196264", borderRadius: "5px" }}>
                                <strong>Let Us Help You Reach Your Business Goals and Growth !</strong>
                            </p>
                        </div>
                        <div className="p-3">
                            <p>Take your business to the next level and streamline your processes like never before.</p>
                        </div>
                        <div className="mt-3">
                            <p><FontAwesomeIcon icon={faEnvelope} /> admin@ajeck.co.nz</p>
                        </div>

                        {/* Your Digital Presence! */}
                        {/* <div className="scroller">
                                <span className="">
                                    Elevate<br />
                                    Innovate<br />
                                    Captivate<br />
                                    Transform
                                </span>
                            </div> */}

                        {/* <p className="fw-light p-2">Your All-in-One Digital Solution!</p> */}

                    </div>
                </div>

                {/* <div className="position-absolute top-100 start-50 translate-middle border rounded p-5 w-50">test</div> */}

            </div>


        </section >
    );
}

export default Home;