import React from "react";

function ContactUs() {
    return (
        <section className="contactUs">
            <div className="contactus d-lg-flex justify-content-center p-5 d-block">
                <div className="p-2">
                    <h3 className="text-center text-white">Have a question or need a custom quote?</h3>
                    <p className="text-center text-white">Contact us on email and we will get to you as soon as possible</p>
                </div>
                <div className="p-2 text-center">
                    <button type="button" className="btn btn-light golden-btn text-white">GET A QUOTE</button>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;