import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWandMagicSparkles, faTruck, faLaptopCode, faRocket, faEnvelopesBulk, faGears } from '@fortawesome/free-solid-svg-icons';

function Services() {
    return (
        <section className="whatweoffer">
            <div className="container my-5">
                <div className="d-flex justify-content-center">
                    <div className="sub-title">
                        <h2 className="corn-flower fw-bold">WHAT WE OFFER</h2>
                    </div>
                </div>
                <p className="text-center mt-4">Explore the limitless possibilities with our comprehensive software solutions. 
                Partner with us for innovation, reliability, and success in the digital realm.
                </p>


                <div className="row mt-5 text-center text-sm-start">
                    <div className="col-lg-6">
                        <div className="card mb-3 service-card">
                            <div className="row g-0">
                                <div className="col-sm-4">
                                    <div className="h-100 d-flex justify-content-center align-items-center light-blue-bg p-2">
                                        <FontAwesomeIcon className="fs-1 service-icon" icon={faWandMagicSparkles} />
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Tailored Software Solutions</h5>
                                        <p className="card-text"><small className="text-body-secondary">
                                        Choose your unique online identity swiftly and effectively to leave a lasting impression.
                                        </small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="card mb-3 service-card">
                            <div className="row g-0">
                                <div className="col-sm-4">
                                    <div className="h-100 d-flex justify-content-center align-items-center light-blue-bg p-2">
                                        <FontAwesomeIcon className="fs-1 service-icon" icon={faTruck} />
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Super Fast Delivery</h5>
                                        <p className="card-text"><small className="text-body-secondary">
                                            Stay ahead in the digital landscape with our state-of-the-art technology stack. 
                                        </small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row text-center text-sm-start">
                    <div className="col-lg-6">
                        <div className="card mb-3 service-card">
                            <div className="row g-0">
                                <div className="col-sm-4">
                                    <div className="h-100 d-flex justify-content-center align-items-center light-blue-bg p-2">
                                        <FontAwesomeIcon className="fs-1 service-icon" icon={faLaptopCode} />
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Web Application Development</h5>
                                        <p className="card-text"><small className="text-body-secondary">
                                        We leverage advanced technologies to ensure your software is robust, scalable, and future-proof.
                                        </small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="card mb-3 service-card">
                            <div className="row g-0">
                                <div className="col-sm-4">
                                    <div className="h-100 d-flex justify-content-center align-items-center light-blue-bg p-2">
                                        <FontAwesomeIcon className="fs-1 service-icon" icon={faRocket} />
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Hosting</h5>
                                        <p className="card-text"><small className="text-body-secondary">
                                            This is a wider card with supporting text below as a natural lead-in to additional content.
                                        </small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row text-center text-sm-start">
                    <div className="col-lg-6">
                        <div className="card mb-3 service-card">
                            <div className="row g-0">
                                <div className="col-sm-4">
                                    <div className="h-100 d-flex justify-content-center align-items-center light-blue-bg p-2">
                                        <FontAwesomeIcon className="fs-1 service-icon" icon={faGears} />
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Digital Marketing</h5>
                                        <p className="card-text"><small className="text-body-secondary">
                                            This is a wider card with supporting text below as a natural lead-in to additional content.
                                        </small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="card mb-3 service-card">
                            <div className="row g-0">
                                <div className="col-sm-4">
                                    <div className="h-100 d-flex justify-content-center align-items-center light-blue-bg p-2">
                                        <FontAwesomeIcon className="fs-1 service-icon" icon={faEnvelopesBulk} />
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Email Services</h5>
                                        <p className="card-text"><small className="text-body-secondary">
                                            This is a wider card with supporting text below as a natural lead-in to additional content.
                                        </small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Services;