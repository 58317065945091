import logo from './logo.svg';
import './App.css';
import Navbar from './common/Navbar';
import Home from './main-pages/Home';
import Services from './home-inc/Services';
import ContactUs from './home-inc/ContactUs';
import Footer from './common/Footer';
import WhyChooseUs from './home-inc/WhyChooseUs';
import Clients from './home-inc/Clients';
import Testimonials from './home-inc/Testimonials';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      {/* <Part1 /> */}
      <Services />
      <WhyChooseUs />
      <Clients />
      {/* <Testimonials/> */}
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
