import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faEnvelope, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { faYoutube, faFacebookF, faInstagram, faTiktok, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
        <footer className="text-sm-start text-white" style={{ backgroundColor: "#082545" }}>
            <div className="container p-4 pb-0">
                <div className="row mt-3">
                    <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0 text-sm-start text-center">
                        <h6 className="text-uppercase fw-bold light-golden">AJECK SYSTEMS</h6>
                        {/* <p className="text-white">
                            Your Solution for jvndkj.
                        </p> */}
                    </div>
                    <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0 text-sm-start text-center">
                        <h6 className="text-uppercase fw-bold mb-4 light-golden">About Us</h6>
                        <p className="">
                            <a href="/theteam" className="text-white text-decoration-none">
                                The Team
                            </a>
                        </p>
                        <p className="">
                            <a href="/values" className="text-white text-decoration-none">
                                Mission & Vision
                            </a>
                        </p>
                    </div>

                    <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0 text-sm-start text-center">
                        <h6 className="text-uppercase fw-bold mb-4 light-golden">Resourcces</h6>
                        <p className="">
                            <a href="/theteam" className="text-white text-decoration-none">
                                Blog
                            </a>
                        </p>
                        <p className="">
                            <a href="/values" className="text-white text-decoration-none">
                                Newsletter
                            </a>
                        </p>
                    </div>

                    <div className="col-lg-3 col-sm-6 mx-auto mb-4 mb-lg-0 text-sm-start text-center">
                        <h6 className="text-uppercase mb-4 fw-bold light-golden">BUSINESS HOURS</h6>
                        <div className="d-flex">
                            <div className="col-1">
                                <FontAwesomeIcon icon={faCalendarDays} />
                            </div>
                            <div className="col-11">
                                <p className="text-white">
                                    Mon-Sat 8:30 am - 8pm  <br />Sun Closed</p>
                            </div>
                        </div>
                    </div>



                    {/* <div className="col-lg-3 col-sm-6 mx-auto mb-4 mb-lg-0 text-sm-start text-center">
                        <h6 className="text-uppercase mb-4 fw-bold light-golden">CONNECT WITH US</h6>
                        <div className="d-flex flex-column justify-content-cenetr">
                            <div className="d-flex justify-content-cenetr">
                                <div className="col-1">
                                    <FontAwesomeIcon icon={faHouse}/>
                                </div>
                                <div className="col-11">
                                    <p className="text-white">
                                        131 Rockfield Road, <br />Auckland, New Zealand</p>
                                </div>
                            </div>
                            <div>
                                <p><FontAwesomeIcon icon={faEnvelope}/> ajecksystems@gmail.com</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>


            <ul className="list-unstyled list-inline footer-social text-center text-white mt-3">
                <li className="list-inline-item"><a href="https://www.facebook.com/ajecksystems"
                    className="btn btn-link text-white m-1" target="_blank">
                    <FontAwesomeIcon icon={faFacebookF} /></a></li>
                <li className="list-inline-item"><a href="https://www.tiktok.com/@ajecksystems"
                    className="btn btn-link text-white m-1" target="_blank">
                    <FontAwesomeIcon icon={faTiktok} /></a></li>
                <li className="list-inline-item"><a href="https://www.linkedin.com/company/ajecksystems"
                    className="btn btn-link text-white m-1" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                <li className="list-inline-item"><a href="https://www.instagram.com/ajecksystems"
                    className="btn btn-link text-white m-1" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} /></a></li>
                <li className="list-inline-item"><a href="https://www.youtube.com/@ajecksystems"
                    className="btn btn-link text-white m-1" target="_blank">
                    <FontAwesomeIcon icon={faYoutube} /></a></li>
            </ul>

            <div className="d-sm-flex d-block justify-content-center text-center p-3 text-white" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                <div className="d-sm-flex d-block justify-content-center">
                    <p>Copyright © 2023 <a className="text-white text-decoration-none" href="/">Ajeck Systems Limited.</a> </p>
                    <p>All Rights Reserved.</p>
                </div>
                <div>
                    <a href="#"
                        className="text-white text-decoration-none blog-text ms-2">
                        Privacy Policy</a> | <a href="#" className="text-white text-decoration-none blog-text">
                        Terms of Use</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;