import React from "react";
import tpf from "../assets/images/tpf.png";
import asilentexplorer from "../assets/images/asilentexplorer.png";
import thebearsenal from "../assets/images/thebearsenal.png";
import thedealnabber from "../assets/images/thedealnabber.png";
import theoddshop from "../assets/images/theoddshop.png";

function Clients() {
    return (
        <section className="ourclients">
            <div className="container my-5">
                <div className="d-flex justify-content-center">
                    <div className="sub-title">
                        <h2 className="corn-flower fw-bold">OUR PARTNER SITES</h2>
                    </div>
                </div>
                <div className="row mt-5 d-flex justify-content-center">
                    <div className="col-sm-2 mb-2 col-6">
                        <a href="https://www.thepuzzlefactory.co/" target="_blank">
                            <img src={tpf} class="card-img" alt="..." />
                        </a>
                    </div>
                    <div className="col-sm-2 mb-2 col-6">
                        <a href="https://www.asilentexplorer.com/" target="_blank">
                            <img src={asilentexplorer} class="card-img" alt="..." />
                        </a>
                    </div>
                    <div className="col-sm-2 mb-2 col-6">
                        <a href="https://www.thebearsenal.com/" target="_blank">
                            <img src={thebearsenal} class="card-img" alt="..." />
                        </a>
                    </div>
                    <div className="col-sm-2 mb-2 col-6">
                        <a href="https://www.thedealnabber.com/" target="_blank">
                            <img src={thedealnabber} class="card-img" alt="..." />
                        </a>
                    </div>
                    <div className="col-sm-2 mb-2 col-6">
                        <a href="https://www.theoddshop.co.nz/" target="_blank">
                            <img src={theoddshop} class="card-img" alt="..." />
                        </a>
                    </div>
                    {/* <div className="col-sm-2 mb-2 col-6">
                        <a href="">
                            <img src={tpf} class="card-img" alt="..." />
                        </a>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default Clients;